import soledis from './global';

const sldSearchWidgetRemoveClick = () => {
  const searchBar =document.querySelector('#header .search-widget-form');
  const button = document.querySelector('#header .search-widget-button');
  if (searchBar !== null && button !== null) {
    button.addEventListener('click', (e) => {
      let field = document.querySelector('#header .search-widget-field');
      if (!searchBar.classList.contains('active')) {
        e.preventDefault();
        searchBar.classList.add('active');
        field.focus();
        let overlay = document.createElement('div');
        overlay.classList.add('sld-overlay');
        overlay.addEventListener('click', () => {
          searchBar.classList.remove('active');
          overlay.remove();
        })
        document.body.append(overlay);
      }
    });
  }
}

const bannerHeight = (headerBanner,headerMain) => {
  if (headerBanner !== null && headerBanner.clientHeight > 0) {
    headerMain.style.top = `-${headerBanner.clientHeight}px`;
  }
}

const menuFixeTransition = () => {
  if (document.querySelectorAll('body#index, body#category').length > 0 && prestashop.responsive.min_width < prestashop.responsive.current_width) {
    let headerMain = document.querySelector('.header-main');
    let headerBanner = document.querySelector('.header-banner');
    if (headerMain !== null) {
      bannerHeight(headerBanner, headerMain);
      let header = document.querySelector('#header');
      if (window.scrollY < 10) {
        headerMain.classList.add('header-transparent');
      }
      const options = {
        rootMargin: '0px',
        threshold: 1.0
      }
      
      const callback = (entries) => {
        if (entries[0].isIntersecting) {
          headerMain.classList.add('header-transparent');
          headerMain.style.top = 0;
        }
        else {
          headerMain.classList.remove('header-transparent');
          bannerHeight(headerBanner, headerMain);
        }
      }
      
      const observerStickyMenu = new IntersectionObserver(callback, options);
    
      observerStickyMenu.observe(header);
    }
  }
}

document.addEventListener('DOMContentLoaded', () => {
  // remove comment if you want the animation to display search bar
  sldSearchWidgetRemoveClick();
  menuFixeTransition();
});