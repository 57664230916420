const homeBlogSlider = () => {
  $('#blog-slider').slick({
    slidesToShow: 2,
    slidesToScroll: 1,
    dots: true,
    arrows: true,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false
        }
      },
    ]
  });
}

$(document).on('ready', () => {
  if ($('body#index').length === 1) {
    homeBlogSlider();
  }
});