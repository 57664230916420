function setBrandLogoSlider(obj, scroll, rtl) {
	$(obj).slick({
		slidesToShow: 4,
		variableWidth: true,
		infinite: true,
		speed: 700,
		autoplay: scroll,
		dots: false,
		arrows: true,
		draggable: false,
		responsive: [
			{
				breakpoint: 768,
				settings: {
				  slidesToShow: 4,
				  infinite: true,
				  draggable: true,
				  arrows: true,
				  swipeToSlide: true,
				}
			},
		]
  	});
  	$(obj).on('beforeChange', function(event, slick, currentSlide, nextSlide){
		$(obj).find('.slick-active img.js-lazy').trigger("appear");
	});
}

$(window).on('load', function() {
	var rtl = false;
  	if (prestashop.language.is_rtl == '1') {
    	rtl = true;
  	}
  	$('.js-brand-logo-slider').each(function() {
	  	var	scroll = $(this).data('autoscroll');
		setBrandLogoSlider($(this), scroll, rtl);
	});
});