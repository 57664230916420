import $ from 'jquery';
import prestashop from 'prestashop';

function initRmaItemSelector() {
  $(`${prestashop.themeSelectors.order.returnForm} table thead input[type=checkbox]`).on('click', function () {
    const checked = $(this).prop('checked');
    $(`${prestashop.themeSelectors.order.returnForm} table tbody input[type=checkbox]`).each(function(_, checkbox) {
      $(checkbox).prop('checked', checked);
    });
  });
}

function setupCustomerScripts() {
  if ($('body#order-detail')) {
    initRmaItemSelector();
  }
}

$(document).ready(setupCustomerScripts);
