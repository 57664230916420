import $ from 'jquery';

import './_aonebrandlogo.js';
import './_aonecolorsfonts.js';
import './_aonecolumnblocks.js';
import './_aonefeaturedcategories.js';
import './_aonehomeblocks.js';
import './_aonemegamenu.js';
import './_aonepopupnewsletter.js';
import './_aoneproductadditional.js';
import './_aoneslideshow.js';
//import './_aonecategoryslider.js';
import './_aonethememanager.js';
import './_update-styles.js';
